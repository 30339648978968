__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


import './_modules/swiper';
import './_modules/collapsible';


document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();

    if (document.body.classList.contains('animate-home')) {

        setTimeout(function () {
            document.body.classList.remove('animate-home');
        }, 1000);

        setTimeout(function () {
            document.body.classList.add('animate-home');
            const logo = document.querySelector('.logo');
            const headerTop = document.querySelector('.header-top');
            const mainMenu = document.querySelector('.main-menu');
            logo.classList.add('scale');
            headerTop.classList.add('slide-down');
            mainMenu.classList.add('slide-up');
        }, 2500);
    }


    // HANDLE CONTACT POP UP

    const contactLink = document.querySelectorAll(".main-menu a[href='/contact']");
    const contactInner = document.getElementById('contact-inner');
    const main = document.querySelector('main');
    const contactClose = document.querySelector('#contact-inner .headline a');

    contactLink.forEach((link) => {
        link.addEventListener("click", function (e) {
            e.preventDefault();
            contactInner.classList.add('show');
        });
    });

    contactClose.addEventListener('click', function (e) {
        contactInner.classList.remove('show');
    });
    main.addEventListener('click', function (e) {
        if (e.target === contactLink) {

        } else {
            contactInner.classList.remove('show');
        }
    });


    // HANDLE OPTIONS

    const order = document.querySelector('button.order a.email');
    const options = document?.querySelectorAll('.option');
    const price = document?.querySelectorAll('.price');
    const selected = document?.querySelector('.selected');


    const dataOption = selected?.dataset.option;

    if (order) {
        const indexOfDash = order.getAttribute('href').indexOf("—");
        const modified = order.getAttribute('href').substring(0, indexOfDash + 1) + " " + dataOption;

        order.setAttribute("href", modified);
    }

    if (options) {
        const orderLink = document.querySelector('.order');
        options.forEach((option) => {
            option.addEventListener("click", () => {
                options.forEach((option) => {
                    option.classList.remove('selected');
                });

                option.classList.add('selected');


                if (price.length > 0) {
                    price.forEach((el) => {
                        el.classList.remove("selected");
                    });
                    if (option.nextElementSibling) {
                        option.nextElementSibling.classList.add('selected');
                    }
                }

                if (order) {

                    const dataOption = option.dataset.option;
                    const indexOfDash = order.getAttribute('href').indexOf("—");
                    const modified = order.getAttribute('href').substring(0, indexOfDash + 1) + " " + dataOption;

                    console.log(modified);

                    order.setAttribute("href", modified);
                }

            });
        });
    }

    if (price.length > 0) {
        price.forEach((el) => {

            el.addEventListener("click", () => {
                const option = el.previousElementSibling;

                options.forEach((option) => {
                    option.classList.remove('selected');
                });

                option.classList.add('selected');

                price.forEach((el) => {
                    el.classList.remove("selected");
                });
                if (option.nextElementSibling) {
                    option.nextElementSibling.classList.add('selected');
                }


                if (order) {
                    const dataOption = option.dataset.option;
                    const indexOfDash = order.getAttribute('href').indexOf("—");
                    const modified = order.getAttribute('href').substring(0, indexOfDash + 1) + " " + dataOption;

                    console.log(modified);

                    order.setAttribute("href", modified);
                }

            });

        });
    }


});