import Swiper from 'swiper';
import {Autoplay, EffectFade, HashNavigation, History, Keyboard, Manipulation, Mousewheel, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import Loadeer from "loadeer";


export const Carousel = () => {

    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiper = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard, Pagination, HashNavigation, History],
            cssMode: false,
            direction: 'horizontal',
            followFinger: false,
            preloadImages: false,
            resizeObserver: true,
            allowTouchMove: true,
            loop: true,
            keyboard: {
                onlyInViewport: true,
            },
            mousewheel: {
                forceToAxis: true,
                sensitivity: 5,
            },
            autoplay: {
                delay: 2000,
                disableOnInteraction: true,
            },
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            effect: 'slide',
            fadeEffect: {
                crossFade: false,
            },
            watchSlidesProgress: true,
            speed: 300,
            on: {
                afterInit: function () {
                    this.autoplay.stop();
                    setTimeout(function () {
                        if (swiper.el.dataset.autoplay === "1") {
                            swiper.autoplay.start();
                        } else {
                            swiper.autoplay.stop();
                        }
                    }, 3000);

                    const images = document.querySelectorAll(".swiper-slide img");

                    const instance = new Loadeer('[data-lazyload]', {
                        onLoaded: (element) => {
                            element.addEventListener('load', ({target}) => {
                                setTimeout(function () {
                                    target.classList.add('has-loaded');
                                }, 500);
                            })
                        }
                    })
                    instance.observe();

                    const activeSlide = document.querySelector('.swiper-slide-active');

                    getLegend(activeSlide);

                },
                slideChangeTransitionEnd: function () {
                    const activeSlide = document.querySelector('.swiper-slide-active');

                    getLegend(activeSlide);
                    changeIndex(activeSlide);

                }
            }
        });

        function getLegend(activeSlide) {
            const date = activeSlide?.dataset.date;
            const title = activeSlide?.dataset.title;
            const client = activeSlide?.dataset.client;
            const category = activeSlide?.dataset.category;

            const legendDate = document.querySelector('.swiper-legend .date');
            const legendTitle = document.querySelector('.swiper-legend .title');
            const legendClient = document.querySelector('.swiper-legend .client');

            if (legendDate && legendTitle && legendClient) {
                legendDate.innerHTML = date + "<sup>Date</sup>";
                legendTitle.innerHTML = title + "<sup>" + category + "</sup>";
                legendClient.innerHTML = client + "<sup>Client</sup>";
            }
        }

        function changeIndex(activeSlide) {
            const activeIndex = activeSlide.dataset.index;
            const indexEl = document?.querySelector('.swiper-index .index');

            if (indexEl) {
                indexEl.innerHTML = activeIndex;
            }

        }

        const options = document.querySelectorAll('body[data-template="commande"] .option-inner');

        options.forEach((option) =>{

            option.addEventListener('click', function (){
                const slideTo = option.dataset.slideto - 1;
                swiper.slideTo(slideTo);
            })

        });

    }
}


Carousel();